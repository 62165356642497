.SingleDatePickerInput {
  border-radius: 5px;
  border: solid 1px #e6e6eb;
  background-color: #ffffff;
  overflow: hidden;
}

.SingleDatePickerInput_calendarIcon,
.DayPicker_calendarIcon {
  width: 16px;
  height: 18px;
  padding: 0;
  margin: 5px 10px 7px 3px;
}

.SingleDatePicker_picker:not(.SingleDatePicker_picker__portal) {
  top: 50px !important;
  border: solid 1px #e6e6eb;
  padding: 0px 30px 10px;
  border-radius: 5px;
}

.datePicker__info {
  padding: 30px 22px 10px;
}

.datePicker__info h2 {
  margin-bottom: 0px;
}

.DateInput {
  width: 75px;
}

.DateInput_input {
  font-size: 14px;
  padding: 2px 5px 2px 10px;
  border-bottom: none;
}

.DateInput_input::placeholder {
  color: #b9babe;
}

.DayPicker__withBorder {
  border: none;
  box-shadow: none;
}

.DayPicker_weekHeader_li {
  color: #b9babe;
  text-transform: lowercase;
}

.customMonthElement .DayPickerNavigation_button {
  top: 25px;
}

.DayPickerNavigation_button {
  position: absolute;
  top: 18px;
  line-height: 0.78;
  border-radius: 3px;
  padding: 3px 0;
}

.DayPickerNavigation_button:first-child {
  left: 5px;
}

.DayPickerNavigation_button:last-child {
  right: 5px;
}

.SingleDatePicker_picker__portal .DayPickerNavigation_button:first-child {
  top: 23px;
}

.SingleDatePicker_picker__portal .DayPickerNavigation_button:last-child {
  top: 23px;
}

.CalendarMonth_caption {
  margin-bottom: 8px;
  font-weight: bold;
}

.CalendarDay__default {
  vertical-align: middle;
  border: 1px solid #e6e6eb;
  color: #b9babe;
  position: relative;
  z-index: 0;
}

.CalendarDay__default:not(.CalendarDay__blocked_calendar):not(.CalendarDay__blocked_out_of_range):hover,
.CalendarDay__highlighted_calendar:not(.CalendarDay__selected):hover {
  background: transparent;
  color: #fff;
}

/* Normal element background style */
.CalendarDay__default:not(.CalendarDay__blocked_calendar):not(.CalendarDay__blocked_out_of_range):after {
  content: '';
  position: absolute;
  width: 90%;
  height: 90%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: transparent;
  border-radius: 100%;
  z-index: -1;
}

/* Normal element background color */
.CalendarDay__default:not(.CalendarDay__blocked_calendar):not(.CalendarDay__blocked_out_of_range):hover:after {
  background-color: #33b6dc;
}

/* Datepicker blocked */
.CalendarDay__blocked_calendar,
.CalendarDay__blocked_out_of_range,
.CalendarDay__blocked_calendar:hover,
.CalendarDay__blocked_out_of_range:hover {
  vertical-align: middle;
  border: 1px solid #e6e6eb;
  color: #b9babe;
  opacity: 0.3;
  background-color: transparent;
}

/* Datepicker active */
.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  color: #fff;
  font-weight: bold;
  background: #fff;
  border: 1px solid #e6e6eb;
}
.CalendarDay__selected:after {
  background-color: #33b6dc !important;
}

/* Datepicker Highlight */
.CalendarDay__highlighted_calendar:not(.CalendarDay__selected) {
  background: #fff;
  color: #fff;
}
.CalendarDay__highlighted_calendar:not(.CalendarDay__selected):after {
  background-color: #666872 !important;
}

.SingleDatePicker_picker__portal {
  background-color: rgba(57, 58, 64, 0.7);
}
